@use "../../scss/variables.scss" as vars;

.firstDiv {
  min-height: 100vmin;
  //   border: 1px solid red;
  position: relative;

  .headerContainer {
    z-index: 2;
    background-color: transparent;

    position: relative;
  }
  img {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 30%;
  }
  .greatings {
    position: relative;
    z-index: 2;
    margin-top: 10.5vmax;
  }
  .buttonContainer {
    margin-top: 10vmax;
    .callBtn {
      height: 50px;
      border: 3px solid white;
      background-color: transparent;
      opacity: 2;
      border-radius: 10px;
    }
  }
}

.pageDiv {
  height: auto;
}
.contactBtn {
  height: 50px;
  border: 1px solid white;
  border-radius: 10px;
  cursor: pointer;
  .btnimg {
    position: relative;
    height: 25px;
    object-fit: contain;
    opacity: 100%;
  }
}

.titoletto {
  font-size: 3rem;
}
