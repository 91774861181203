.headerContainer {
  height: 50px;
  z-index: 2;
  //   border: 1px solid red;
  .logoContainer {
    // border: 1px solid blue;
    // height: 100%;
    
    .logoImg {
      position: relative;
      // height: 100%;
      // width: 100%;
      object-fit: cover;
      opacity: 100%;
    }
  }
}
