@use "../../scss/variables.scss" as vars;

.firstDiv {
  height: 100vmin;
  //   border: 1px solid red;
  position: relative;

  .headerContainer {
    z-index: 2;
    background-color: transparent;

    position: relative;
  }
  img {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 30%;
  }
  .greatings {
    position: relative;
    z-index: 2;
    margin-top: 10.5vmax;
  }
  .buttonContainer {
    margin-top: 10vmax;
    .callBtn {
      height: 50px;
      border: 3px solid white;
      background-color: transparent;
      opacity: 2;
      border-radius: 10px;
    }
  }
}

.pageDiv {
  height: auto;
  .imageScorrevole {
    margin-top: 90px;
    // border: 1px solid red;
    max-height: 570px;
    overflow: hidden;
    .imageContainer {
      //   max-height: 100%;
      //   width: 100%;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
}

/* Contenitore principale */
.container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Spaziatura tra le righe */
  margin-top: 90px;
  height: 500px;
  overflow: hidden;
  /* Righe */
  .row {
    display: flex;
    justify-content: space-between; /* Spaziatura uniforme */
    gap: 10px; /* Spazio tra le immagini */
    height: 200px;
    overflow: hidden;
    /* Contenitore delle immagini */
    .image_container {
      flex: 1; /* Le immagini si adattano allo spazio disponibile */
      max-width: 24%; /* Ogni immagine occupa circa il 25% */
      max-height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Mantiene il rapporto d'aspetto */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombreggiatura (opzionale) */
      }
    }
  }
}

.partnerContainer {
  height: 120px;
  //   border: 1px solid red;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.contactBtn {
  height: 50px;
  border: 1px solid white;
  border-radius: 10px;
  cursor: pointer;
  .btnimg {
    position: relative;
    height: 25px;
    object-fit: contain;
    opacity: 100%;
  }
}

.titoletto {
  font-size: 3rem;
}
