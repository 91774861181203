@use "../../scss/variables.scss" as vars;

.firstDiv {
  min-height: 100vmin;
  //   border: 1px solid red;
  position: relative;

  .headerContainer {
    z-index: 2;
    background-color: transparent;

    position: relative;
  }
  img {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 30%;
  }
  .greatings {
    position: relative;
    z-index: 2;
    margin-top: 10.5vmax;
  }
  .buttonContainer {
    margin-top: 10vmax;
    .callBtn {
      height: 50px;
      border: 3px solid white;
      background-color: transparent;
      opacity: 2;
      border-radius: 10px;
    }
  }
}

.planContainer {
  position: relative;
  .plan {
    cursor: pointer;
    border: 2px solid vars.$color-dark_yellow;
    border-radius: 20px;
    height: 320px;
    // padding: 20px;
    z-index: 2;
    position: relative;
    // background-color: #292929;
    overflow: hidden;
    .imageContainer {
      height: 100%;

      // bottom: 20px;
      width: 100%;
      .icona {
        opacity: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .title{
        position: absolute;
        bottom: 20px;
        z-index: 3;
    }
  }
  .planTrasled {
    border: 2px solid vars.$color-dark_yellow;
    border-radius: 20px;
    height: 320px;
    // padding: 20px;
    overflow: hidden;
    z-index: 1;
    position: absolute;
    transform: translate(-20px, 15px);
    .icona {
      opacity: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.menu {
  height: auto;
  .arrowContainer {
    position: relative;
    height: 50px;
    .arrowImage {
      //   height: 100%;
      opacity: 100%;
      //   width: 100%;
      object-fit: contain;
    }
  }
}
